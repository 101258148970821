<template>
  <div>
    <v-card-text :style="`height: ${height}px; overflow: auto`" class="pa-0">
      <v-fab-transition v-if="canAdd">
        <v-btn fab dark color="green" absolute bottom small style="bottom: 25px" left class="v-btn--example" @click="$emit('addDocs')">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-fab-transition>
      <a-view-filesdata :value="data.filesData" :category="category" />
    </v-card-text>
  </div>
</template>

<script>
import { getAccess, popupMenu } from "@/components/mixings";
export default {
  mixins: [getAccess, popupMenu],
  components: {},
  props: {
    height: { type: Number, default: 300 },
    category: Number,
    data: Object,
  },
  data() {
    return {
      showAddDocDialog: false,
      addDocModel: null,
    };
  },
  created() {},
  watch: {},
  computed: {
    canAdd() {
      return false;
      if (this.$root.profile.role == 20) return false;
      return true;
    },
  },
  methods: {},
};
</script>
